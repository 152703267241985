@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  @apply text-skyblue-600;

  &:visited {
    @apply text-skyblue-600;
  }

  &:hover, &:active, &:focus {
    @apply text-skyblue-800;
  }
}

svg.icon {
  display: inline-block;
  margin-top: -2px;
  height: 20px;
  width: 20px;
}

/* Spinner */
.spinner {
  border-top-color: #0b8eb6;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Navigation */
.logo {
  max-width: 100%;
}

@media (max-width: 767px) {
  .mobile__menu__open {
    position: absolute;
    background: white;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    text-align: left;
    width: 80%;
    box-shadow: -10px 0 6px -1px rgba(0, 0, 0, 0.1), -10px 0 4px -1px rgba(0, 0, 0, 0.06);
  }
}

/* Alert */

/* Live session */
.channel__container {
  height: 300px;
}

/* Sponsor */
.sponsor {
  filter: grayscale(100%);

  &:hover {
    filter: none;
  }
}

/* Channel info */
.channelinfo__discord {
  svg {
    display: inline-block;
    height: 20px;
    width: 20px;
  }
}

/* Session */
.grid__session__title {
  button {
    &:hover, &:active, &:focus {
      @apply text-skyblue-800;
    }
  }
}

.grid__session__feedback {
  display: block;
}

.modaldialog {
  z-index: 999;
  width: 100%;
  height: 100vh;

  .modaldialog__overlay {
    background-color: rgba(0,0,0,.8);
  }

  .modaldialog__container__close {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
}

/* Speakers overview */
.speakers__overview__details {
  img {
    filter: grayscale(100%);
  }

  &:hover {
    @apply bg-gray-100;

    img {
      filter: none;
    }
  }
}

/* Footer */
footer {
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 -2px 4px -1px rgba(0, 0, 0, 0.06);
}